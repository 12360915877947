$(function () {

// Ввод только цифр
	$('.js-number').keypress(function(e){
		let charCode = (e.which) ? e.which : e.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		  return false;
		}
	});

// Деактивация кнопки отправки формы при снятии галочки с чекбокса согласия
	$('body').on('change', '.js-agree', function () {
		let $this = $(this);
		let btn = $this.closest('.js-form').find('.submit');
		if ($this.is(':checked')) {
			btn.removeAttr("disabled");
		} else {
			btn.attr("disabled", true);
		}
	});


// Валидация формы
	let formValidate = function() {

		let form = $('.js-form');

		form.each(function(){
			let thisForm = $(this);

			thisForm.validate({
				focusInvalid: false,
				highlight: function(element) {
				  $(element).closest(".form-field").addClass('form-field--error');
					thisForm.find('button').prop("disabled", true);
				},
				unhighlight: function(element) {
				  $(element).closest(".form-field").removeClass('form-field--error');
					thisForm.find('button').prop("disabled", false);
				},
				submitHandler: function(form, event){		
					event.preventDefault();					
						let formData = new FormData(thisForm[0]);					
						$.ajax({
							url: '/wp-admin/admin-ajax.php',
							type: 'POST',
							processData: false,
							contentType: false,
							data: formData,
							beforeSend: function() {							
								thisForm.find('button').attr('disabled', 'disabled');
							},
							success: function(data) {
								console.log(data);
								thisForm.find('button').prop('disabled', false);




								if (data['success'] == false) {
					
									$('.result-query').addClass('error').html(data['data']['message']);
									$('.form-field').removeClass('form-field--error');
									$.each(data['data']['focus'], function(index, id) {									
										$('input[name="'+id+'"]').parent().addClass('form-field--error');
									});


									let $popup = $('.js-popup');
									const $elem = $('.js-popup[data-block="'+thisForm.data('thank')+'"]');
									console.log($elem);
									$popup.removeClass('is-show');
									$elem.addClass('is-show');

								} else {
									// $('.result-query').html(data['data']['message']);
					
									let $popup = $('.js-popup');
									$popup.removeClass('is-show');

									const $elem = $('.js-popup[data-block="'+thisForm.data('thank')+'"]');
									$elem.addClass('is-show');

									setTimeout(function() {
										$popup.removeClass('is-show');
									}, 3000);
								}
							},
							error: function(error) {
							
							}
						});

					return false;
				}
			});

		});
	};

	formValidate();

	window.formValidate = formValidate;

	$B.on('click', 'label.error',function(){
		$(this).hide().siblings('input,textarea').focus();

	});

	$B.on('keyup', 'input',function(){
		$(this).siblings('label.error').hide();
	});

	$B.on('change', '.js-select', function(){
		console.log('rfd')
		$(this).closest('.form-field').removeClass('form-field--error');
	})

	$('.popup-thank').on('click', function(){
		$(this).removeClass('is-show');
	});

	

});


