$(function () {
	let $select = $('.js-select');
	// let $selectInput = $('.js-select-input');
	// let $selectFilter = $('.js-select-filter');


	let initSelect = function() {
		$('.js-select').select2({
			dropdownAutoWidth : true,
			containerCssClass: 'select',
			dropdownCssClass: 'no-input'
		});
	}
	initSelect();


	let initSelectLabel = function() {
		$('.js-select-label')
			.select2({
				dropdownAutoWidth : true,
				containerCssClass: 'select',
				dropdownCssClass: 'no-input'
			})
			.on('change', function(e){
				const labelTxt = $(e.target).attr('data-label');
				if(!labelTxt) return;
				const $selectionBox = $(e.target).siblings('.select2').find('.select2-selection__rendered');
				const labelTemplate = `<span class="select-label">${labelTxt}&nbsp;</span>`;
				$selectionBox.prepend(labelTemplate);
			})
			.trigger('change');

			$('.js-select-default')
				.select2({
					dropdownAutoWidth : true,
					containerCssClass: 'select',
					dropdownCssClass: 'no-input'
				})
	}
	initSelectLabel();

	// declaring for global visibility
	window.$select = $select;
	window.initSelect = initSelect;
	// window.$selectInput = $selectInput;
	// window.$selectFilter = $selectFilter;
	// window.initSelectInput = initSelect;
	// window.initSelectSearch = initSelect;
});
